<template>
  <AdminLK v-if="isManager && !getFakeUser"/>
  <ApplicantsKorolev v-else-if="(isManager && getFakeUser) || !isExpert  && !isTest"/>
</template>

<script>
import {mapGetters} from "vuex";
import ApplicantsKorolev from "@/2024/components/applicants/ApplicantsKorolev";
import AdminLK from "@/2024/components/applicants/admin/korolev/AdminLK";

export default {
  name: "ApplicantsKorolevView",
  components: {AdminLK, ApplicantsKorolev},
  computed: {
    ...mapGetters('keycloak', {
      getFakeUser: 'getFakeUser',
      isManager: 'isManager',
      isExpert: 'isExpert',
      isTest: "isTest"
    }),
  },
  created() {
    if (this.isExpert && !this.isManager) {
      this.$router.push({name: 'ApplicantsSelectionKorolev2024'});
    }
  }
}
</script>

<style scoped>

</style>