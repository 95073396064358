<template>
  <div class="applicants">
    <div style="display: flex; flex-direction: row">
      <h2 class="applicants__header" style="margin-right: 19px">Претенденты</h2>
      <span class="applicants__sum" v-html="orgArray.length"></span>
      <button
        class="usual-btn usual-btn_bold"
        style="margin: 0 20px 0 0"
        @click="printingOrders = true">
        Печать приказов
      </button>
      <router-link :to="{ name: 'JournalsEditing2024' }">
        <button class="usual-btn usual-btn_bold" style="margin: 0 20px 0 auto">
          Редактирование журналов
        </button>
      </router-link>
      <button
        class="usual-btn usual-btn_bold"
        style="margin: 0 20px 0 0"
        @click="downloadDialog = true">
        Выгрузка
      </button>
      <router-link :to="{ name: 'ApplicantsSelectionKorolev2024' }">
        <button class="usual-btn usual-btn_bold" style="margin: 0 20px 0 auto">
          Отбор претендентов
        </button>
      </router-link>
      <router-link :to="{ name: 'KonvertRegistrationKorolev2024' }">
        <button class="usual-btn usual-btn_bold" style="margin: 0 0 0 auto">
          Регистрация конвертов
        </button>
      </router-link>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 17px; margin-bottom: 22px">
      <v-text-field
        v-model="search"
        label="Начните набирать название организации..."
        style="max-width: 400px"/>
      <v-text-field
        v-model="searchFio"
        label="Начните набирать фамилию..."
        style="max-width: 400px; margin-left: 40px"/>
    </div>
    <v-dialog v-model="printingOrders" width="740px">
      <v-card class="applicantsOrgDocs" style="min-height: 590px; padding-bottom: 30px">
        <h2 class="applicantsOrgDocs__header">Печать приказов</h2>
        <img
          @click="printingOrders = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div" v-for="order in orders" :key="order.path">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">{{ order.text }}</p>
            <button
              v-if="!order.loading"
              class="usual-btn usual-btn_bold"
              @click="getSelectedOrder(order.path)"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <button class="usual-btn" style="margin: 23px auto 0 25px" @click="printingOrders = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>
    <!--                    applicantDocsDialog                     -->
    <v-dialog v-model="applicantDocsDialog" width="740px">
      <v-card
        class="applicantsOrgDocs"
        :style="docs.oldFioRow ? 'min-height: 750px' : 'min-height: 680px'">
        <h2 class="applicantsOrgDocs__header">Заявка претендента</h2>
        <img
          @click="applicantDocsDialog = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column">
            <p class="document-name" style="font-weight: 500">{{ docs.fio }}</p>
            <p
              class="document-file document-file_empty"
              style="color: #89a0b0; border-bottom: none">
              {{ docs.info }}
            </p>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Опись</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            @click="getInventory(docs.rowID)"
            alt="print"
            class="document-print"/>
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"></v-file-input>
          <!--    визуальная заглушка     -->
          <img
            style="visibility: hidden"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Лист проверки</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getCheckList(docs.rowID)"
            class="document-print"/>
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"></v-file-input>
          <!--    визуальная заглушка     -->
          <img
            style="visibility: hidden"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Характеристика-рекомендация претендента</p>
            <p
              class="document-file"
              v-html="character1Computed"
              v-if="character1Computed !== 'Файл не загружен'"
              @click="
                getFile(docs.character1.uuid, docs.character1.fileNameOrig, docs.character1.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getAppendix1(docs.rowID)"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="character1File"
            :style="applicantDocsDisabled && !docs.character1 ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_1', character1File)"></v-file-input>
          <img
            :style="character1Computed === 'Файл не загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_CHARACTER_1')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Приложение к характеристике-рекомендации</p>
            <p
              class="document-file"
              v-html="character2Computed"
              v-if="character2Computed !== 'Файл не загружен'"
              @click="
                getFile(docs.character2.uuid, docs.character2.fileNameOrig, docs.character2.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getAppendix2(docs.rowID)"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="character2File"
            :style="applicantDocsDisabled && !docs.character2 ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_2', character2File)"></v-file-input>
          <img
            :style="character2Computed === 'Файл не загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_CHARACTER_2')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">
              Согласие на обработку, хранение и передачу персональных данных
            </p>
            <p
              class="document-file"
              v-html="approvalComputed"
              v-if="approvalComputed !== 'Файл не загружен'"
              @click="
                getFile(docs.approval.uuid, docs.approval.fileNameOrig, docs.approval.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="approvalFile"
            :style="applicantDocsDisabled && !docs.approval ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOGL', approvalFile)"></v-file-input>
          <img
            :style="approvalComputed === 'Файл не загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_SOGL')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Выписка из решения учёного (научного, научно-технического) совета</p>
            <p
              class="document-file"
              v-html="counsilDecisionComputed"
              v-if="counsilDecisionComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.councilDecision.uuid,
                  docs.councilDecision.fileNameOrig,
                  docs.councilDecision.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="councilDecisionFile"
            :style="applicantDocsDisabled && !docs.councilDecision ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOVET', councilDecisionFile)"></v-file-input>
          <img
            :style="counsilDecisionComputed === 'Файл не загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_SOVET')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Сведения об успеваемости</p>
            <p
              class="document-file"
              v-html="academicPerformanceComputed"
              v-if="academicPerformanceComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.academicPerformance.uuid,
                  docs.academicPerformance.fileNameOrig,
                  docs.academicPerformance.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="academicPerformanceFile"
            :style="applicantDocsDisabled && !docs.academicPerformance ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_USPEH', academicPerformanceFile)"></v-file-input>
          <img
            :style="academicPerformanceComputed === 'Файл не загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_USPEH')"/>
        </div>
        <div class="applicantsOrgDocs-div" v-if="docs.mainCourse == 1">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Копия диплома предыдущего уровня подготовки</p>
            <p
                class="document-file"
                v-html="prevDiplomaCopyComputed"
                v-if="prevDiplomaCopyComputed !== 'Файл не загружен'"
                @click="
                getFile(
                  docs.prevDiplomaCopy.uuid,
                  docs.prevDiplomaCopy.fileNameOrig,
                  docs.prevDiplomaCopy.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
              src="@/assets/icons/print.svg"
              alt="print"
              style="visibility: hidden"
              class="document-print"
          />
          <v-file-input
              prepend-icon="mdi-folder-open-outline"
              v-model="prevDiplomaCopyFile"
              :style="
              !applicantDocsDisabled || docs.prevDiplomaCopy || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
              accept="application/pdf"
              placeholder="Выберите файл"
              @change="filePost('FILE_COPY_DIPLOM', prevDiplomaCopyFile)"></v-file-input>
          <img
              :style="
              prevDiplomaCopyComputed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
              src="@/assets/icons/delete-round.svg"
              alt="delete"
              class="document-delete"
              @click="deleteFile('FILE_COPY_DIPLOM')"/>
        </div>
        <div class="applicantsOrgDocs-div" v-if="docs.oldFioRow">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Документ, подтверждающий смену ФИО</p>
            <p
              class="document-file"
              v-html="oldFioComputed"
              v-if="oldFioComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.oldFioObject.uuid,
                  docs.oldFioObject.fileNameOrig,
                  docs.oldFioObject.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <!--заглушка для стилей-->
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="oldFioFile"
            :style="applicantDocsDisabled && !docs.oldFioObject ? '' : 'visibility: hidden'"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_OLD_FIO', oldFioFile)"></v-file-input>
          <img
            :style="oldFioComputed === 'Файл не загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_OLD_FIO')"/>
        </div>
        <button
          class="usual-btn"
          style="margin: 23px auto 26px 25px"
          @click="applicantDocsDialog = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloadDialog" width="740px">
      <v-card class="applicantsOrgDocs" style="min-height: 460px">
        <h2 class="applicantsOrgDocs__header">Выгрузка</h2>
        <img
          @click="downloadDialog = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Данные претендентов</p>
            <button
              v-if="!loader.button1"
              class="usual-btn usual-btn_bold"
              @click="getApplicantDataExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Отбор претендентов</p>
            <button
              v-if="!loader.button2"
              class="usual-btn usual-btn_bold"
              @click="getApplicantSelectionExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Количество поданных заявок</p>
            <button
              v-if="!loader.button3"
              class="usual-btn usual-btn_bold"
              @click="getSubmittedApplicationsSumExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Количество отобранных заявок</p>
            <button
              v-if="!loader.button4"
              class="usual-btn usual-btn_bold"
              @click="getSelectedApplicationsSumExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Уставы, ЕГРЮЛ</p>
            <button
              v-if="!loader.button5"
              class="usual-btn usual-btn_bold"
              @click="getOrgDocsExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <button
          class="usual-btn"
          style="margin: 23px auto 26px 25px"
          @click="applicantDocsDialog = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>
    <!--                    applicantDocsDialog                     -->

    <GridTable
      :data-rows="showingServerData"
      :headers="tableHeaders.orgMassive"
      :headers-for-rows="tableHeaders.orgMassive"
      :num-cols="tableHeaders.countCells"
      :loading-data="loading"
      :borderTop="'none'"
      style="background: #ffffff; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06); border-radius: 4px">
      <template #num="{ row }">
        <span>{{ row.rowOrder }}</span>
      </template>
      <template #orgInfo="{ row }">
        <span @click="clickOnRow(row)">{{ row.mainOrg.owner.name }}</span>
        <span class="orgId">{{ row.mainOrg.id }}</span>
      </template>
      <template #docs="{ row }">
        <div class="block__wrapper" @click="applicantDocsDialogOpen(row)">
          <div class="applicantAdmin__docs"></div>
        </div>
      </template>
      <template #fio="{ row }">
        <div style="display: flex; flex-direction: column; margin-left: 16px">
          <span>{{ row.mainLastName }}</span>
          <span>{{ `${row.mainFirstName} ${row.mainSecondName}` }}</span>
          <div class="educInfo" style="display: flex; flex-direction: row">
            <span>
              {{
                `${row.mainEducationStatus.educationStatus} • ${row.mainFo.name} • ${row.mainCourse} курс/год обучения`
              }}
            </span>
          </div>
        </div>
      </template>
      <template #konvert="{ row }">
        <div v-if="row.konvertConvert" style="display: flex; flex-direction: row">
          <img src="@/assets/icons/konvert.svg" style="margin-right: 10px; align-self: baseline"/>
          <div style="display: flex; flex-direction: column">
            <span>{{ row.konvertConvert }}</span>
            <span class="konvertDate" v-if="row.konvertConvertDate">
              {{ dateFormat(row.konvertConvertDate.slice(0, 10)) }}
            </span>
          </div>
        </div>
      </template>
      <template #stpType="{ row }">
        <div style="display: flex; flex-direction: row; align-items: center">
          <img src="@/assets/icons/cup-grey.svg" style="margin-right: 10px"/>
          <span>{{ row.mainStpType.name }}</span>
        </div>
      </template>
      <template #actions="{ row }">
        <div class="applicantBlocked__wrapper">
          <div
            class="applicant__blocked"
            v-if="orgArray.find((el) => el.id === row.id).blockFlBlock"
            @click="unblockRow(row)"></div>
          <div class="applicant__opened" v-else @click="blockRow(row)"></div>
        </div>
      </template>
    </GridTable>
    <div class="mt-3 d-flex flex-row justify-center align-center">
      <v-autocomplete
        class="pagination-table mr-5"
        v-model="userPages"
        :items="pagesPaginate"
        dense
        filled
        rounded
        height="25"
        style="max-width: 120px"
        @change="changePage($event)"></v-autocomplete>
      <v-pagination
        v-model="page"
        :length="pagesVuetify"
        :total-visible="7"
        @input="changePage($event)">
      </v-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import {tablePagination} from '@/2024/components/applicants/admin/journals/tablePagination';
import {GridTable} from '@frontenddevelopers/ined-components/src/lib';
import ApplicantsMainAdminJson from '@/2024/components/applicants/json/ApplicantsMainAdminJson';

export default {
  name: 'AdminLK',
  components: {GridTable},
  mixins: [tablePagination],
  data() {
    return {
      printingOrders: false,
      tableHeaders: [],
      orgArray: [],
      downloadDialog: false,
      applicantDocsDialog: false,
      loading: false,
      character1File: null,
      character2File: null,
      approvalFile: null,
      councilDecisionFile: null,
      academicPerformanceFile: null,
      oldFioFile: null,
      search: '',
      searchFio: '',
      docs: {
        row: null,
        rowID: null,
        fio: null,
        info: null,
        oldFioRow: false,
        approval: {},
        character1: {},
        character2: {},
        councilDecision: {},
        academicPerformance: {},
        oldFioObject: {},
      },
      loader: {
        button1: false,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
      },
      orders: [
        {
          text: 'Печать приказа по студентам',
          path: 'korolev/1',
          loading: false,
        },
        {
          text: 'Печать приказа по аспирантам',
          path: 'korolev/2',
          loading: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getFakeUser: 'getFakeUser',
    }),

    character1Computed() {
      if (this.docs.character1) {
        return `${this.docs.character1.fileNameOrig}.${this.docs.character1.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    character2Computed() {
      if (this.docs.character2) {
        return `${this.docs.character2.fileNameOrig}.${this.docs.character2.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    approvalComputed() {
      if (this.docs.approval) {
        return `${this.docs.approval.fileNameOrig}.${this.docs.approval.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    counsilDecisionComputed() {
      if (this.docs.councilDecision) {
        return `${this.docs.councilDecision.fileNameOrig}.${this.docs.councilDecision.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    academicPerformanceComputed() {
      if (this.docs.academicPerformance) {
        return `${this.docs.academicPerformance.fileNameOrig}.${this.docs.academicPerformance.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    oldFioComputed() {
      if (this.docs.oldFioObject) {
        return `${this.docs.oldFioObject.fileNameOrig}.${this.docs.oldFioObject.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    applicantDocsDisabled() {
      if (
        this.docs.row?.blockFlBlock === true &&
        this.docs.row?.adminAgreePatchAdminTbl5Agree === null
      ) {
        return true;
      } else {
        return false;
      }
    },

    prevDiplomaCopyComputed() {
      if (this.docs.prevDiplomaCopy) {
        return `${this.docs.prevDiplomaCopy.fileNameOrig}.${this.docs.prevDiplomaCopy.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    showingData() {
      return this.orgArray.filter(
        (el) =>
          (!this.searchFio ||
            `${el.mainLastName} ${el.mainFirstName} ${el.mainSecondName}`
              .toLowerCase()
              .search(this.searchFio.toLowerCase()) !== -1) &&
          (!this.search ||
            el.mainOrg.owner.name.toLowerCase().search(this.search.toLowerCase()) !== -1),
      );
    },
  },
  created() {
    this.getAllAdmin();
    this.tableHeaders = ApplicantsMainAdminJson(this);
  },
  methods: {
    ...mapActions('keycloak', {initFakeUser: 'initFakeUser'}),
    async getSelectedOrder(path) {
      // print
      const selectedOrder = this.orders.find((order) => order.path === path);
      selectedOrder.loading = true;
      let uri = `/api/2024/reports/grant_recipients/${path}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      selectedOrder.loading = false;
      this.saveByteArray2(`${selectedOrder.text}.docx`, responseAxios.data, 'docx');
    },
    async getAllAdmin() {
      this.loading = true;
      let uri = `/api/2024/korolev/getAll`;
      try {
        const res = await axios.get(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray = res.data;
          for (let a = 0; a < this.orgArray.length; a++) {
            this.orgArray[a].rowOrder = a + 1;
          }
          this.loading = false;
          console.log('getAllAdmin успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async filePost(fileType, file) {
      const formData = new FormData();
      formData.append('fileType', fileType);
      formData.append('file', file);
      formData.append('rowId', this.docs.rowID);
      if (file.type === "application/pdf") {
        try {
          const res = await axios.post(`/api/2024/korolev/upload/${fileType}`, formData, {
            headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
          });
          if (res.data) {
            if (fileType === 'FILE_CHARACTER_1') {
              this.docs.character1 = res.data.character1;
            } else if (fileType === 'FILE_CHARACTER_2') {
              this.docs.character2 = res.data.character2;
            } else if (fileType === 'FILE_SOGL') {
              this.docs.approval = res.data.approval;
            } else if (fileType === 'FILE_SOVET') {
              this.docs.councilDecision = res.data.councilDecision;
            } else if (fileType === 'FILE_COPY_DIPLOM') {
              this.docs.prevDiplomaCopy = res.data.copyDiplom;
            } else if (fileType === 'FILE_USPEH') {
              this.docs.academicPerformance = res.data.academicPerformance;
            } else if (fileType === 'FILE_OLD_FIO') {
              this.docs.oldFioObject = res.data.oldFio;
            }

            await this.getAllAdmin();
            this.loading = false;
            this.character1File = null;
            this.character2File = null;
            this.approvalFile = null;
            this.councilDecisionFile = null;
            this.academicPerformanceFile = null;
            this.oldFioFile = null;
            console.log('filePost успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else {
        alert(`файл должен соответствовать формату pdf`)
      }

    },

    async getFile(id, name, ext) {
      let uri = `/api/2024/file/scan/download?file=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext);
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },

    saveByteArray2(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // link.target = '_blank'; // вариант открытия в новом окне
      link.download = reportName; // вариант скачивания
      link.click();
      this.loader = {
        button1: false,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
        button6: false,
        button7: false,
      };
    },

    async deleteFile(ID, fileType) {
      // fileType - костыль на визуальное удаление файлов налету
      this.loading = true;
      let uri = `/api/2024/korolev/delete/${fileType}?rowId=${this.docs.rowID}`;
      try {
        const res = await axios.delete(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          if (fileType === 'FILE_CHARACTER_1') {
            this.docs.character1 = null;
          } else if (fileType === 'FILE_CHARACTER_2') {
            this.docs.character2 = null;
          } else if (fileType === 'FILE_SOGL') {
            this.docs.approval = null;
          } else if (fileType === 'FILE_SOVET') {
            this.docs.councilDecision = null;
          } else if (fileType === 'FILE_COPY_DIPLOM') {
            this.docs.prevDiplomaCopy = null;
          } else if (fileType === 'FILE_USPEH') {
            this.docs.academicPerformance = null;
          } else if (fileType === 'FILE_OLD_FIO') {
            this.docs.oldFioObject = null;
          }

          await this.getAllAdmin();
          this.loading = false;
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    dateFormat(arg) {
      let x = arg.replace(/-/g, '.');
      let years = x.slice(0, 4);
      let days = x.slice(-2);
      let months = x.slice(4, -2);
      return `${days}${months}${years}`;
    },

    async blockRow(row) {
      try {
        const res = await axios.put(`/api/2024/korolev/block?rowId=${row.id}`, '', {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray.find((el) => el.id === row.id).blockFlBlock = true;
          // this.orgArray[row.rowOrder - 1] = res.data;
          console.log('blockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async unblockRow(row) {
      try {
        const res = await axios.put(`/api/2024/korolev/unBlock?rowId=${row.id}`, '', {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray.find((el) => el.id === row.id).blockFlBlock = false;
          // this.orgArray[row.rowOrder - 1] = res.data;
          console.log('unblockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getInventory(ID) {
      let uri = `/api/2024/reports/korolev/inventory/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getCheckList(ID) {
      // print
      let uri = `/api/2024/reports/korolev/check_list/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getAppendix1(ID) {
      // print
      let uri = `/api/2024/reports/korolev/appendix_1/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getAppendix2(ID) {
      // print
      let uri = `/api/2024/reports/korolev/appendix_2/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getApplicantDataExcel() {
      // print
      this.loader.button1 = true;
      let uri = `/api/2024/reports/applicant_data/korolev`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Данные Претендентов(Королев).xlsx`, responseAxios.data, 'xlsx');
    },

    async getApplicantSelectionExcel() {
      // print
      this.loader.button2 = true;
      let uri = `/api/2024/reports/applicant_selection/korolev`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Отбор Претендентов(Королев).xlsx`, responseAxios.data, 'xlsx');
    },

    async getSubmittedApplicationsSumExcel() {
      // print
      this.loader.button3 = true;
      let uri = `/api/2024/reports/selected_applications/korolev`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Количество поданных заявок(Королев).xlsx`, responseAxios.data, 'xlsx');
    },

    async getSelectedApplicationsSumExcel() {
      // print
      this.loader.button4 = true;
      let uri = `/api/2024/reports/submitted_applications/korolev`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(
        `Количество отобранных заявок(Королев).xlsx`,
        responseAxios.data,
        'xlsx',
      );
    },

    async getOrgDocsExcel() {
      // print
      this.loader.button5 = true;
      let uri = `/api/2024/reports/org_documents/korolev`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Уставы, ЕГРЮЛ.xlsx`, responseAxios.data, 'xlsx');
    },

    async applicantDocsDialogOpen(row) {
      this.docs.row = row;

      if (
        row.mainEdit2022OldFirstName ||
        row.mainEdit2022OldLastName ||
        row.mainEdit2022OldSecondName
      ) {
        this.docs.oldFioRow = true;
      } else {
        this.docs.oldFioRow = false;
      }

      this.character1File = null;
      this.character2File = null;
      this.approvalFile = null;
      this.councilDecisionFile = null;
      this.academicPerformanceFile = null;
      this.oldFioFile = null;

      this.docs.rowID = row.id;
      this.docs.approval = row.approval;
      this.docs.character1 = row.character1;
      this.docs.character2 = row.character2;
      this.docs.councilDecision = row.councilDecision;
      this.docs.academicPerformance = row.academicPerformance;
      this.docs.prevDiplomaCopy = row.copyDiplom;
      this.docs.oldFioObject = row.oldFio;
      this.docs.mainCourse = row.mainCourse;
      this.docs.fio = `${row.mainLastName} ${row.mainFirstName} ${row.mainSecondName}`;
      this.docs.info = `${row.mainEducationStatus.educationStatus} • ${row.mainFo.name} • ${row.mainCourse} курс/год обучения`;
      this.applicantDocsDialog = true;
      console.log('applicantDocsDialogOpen');
    },

    async clickOnRow(e) {
      await this.initFakeUser({
        id: e.mainOrg.owner.id,
        name: e.mainOrg.owner.name,
        admin: true,
      });
      await this.$router.push('/info');
      await this.$router.push('/korolev-2024/applicants');
    },
  },
};
</script>

<style lang="scss"></style>
